type Props = {
  schoolYear: string;
};
export default function Header(props: Props) {
  return (
    <header className="bg-gradient-to-br from-red-800 to-red-600 flex items-center print:hidden ">
      <div>
        <img
          src="/windmill-logo.png"
          alt="Windmill Logo"
          className="p-2 w-24 h-24"
        />
      </div>
      <div className="text-white px-10">
        <h2 className="md:text-2l text-xl lg:text-2xl mt-5 md:mt-3">
          {`New Student Registration (${props.schoolYear})`}
        </h2>
        <h6 className="md:text-base  text-sm lg:text-base mt-2">
          www.amaisd.org • 7200 I-40 West • Amarillo, Texas 79106 • (806)
          326-1000
        </h6>
      </div>
    </header>
  );
}
